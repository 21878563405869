import React                       from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import MoreExpander from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import Link         from '@interness/web-core/src/components/elements/Link/Link';

const InfoLetters = () => {

  const data = useStaticQuery(graphql`
      query MyQuery {
          allDirectusMediaCollection(filter: {name: {regex: "/(?=Informationsbrief)/g"}}) {
              nodes {
                  name
                  media {
                      file {
                          filename_download
                          localFile {
                              publicURL
                          }
                      }
                  }
              }
          }
      }
  `);


  return (
    <div>
      {data.allDirectusMediaCollection.nodes.map(letter => (
        <MoreExpander key={letter.name} title={`${letter.name}`}>
          <ul>
            {letter.media.map(file => (
              <li key={file.file.filename_download}><Link external
                                                          to={file.file.localFile.publicURL}>{file.file.filename_download}</Link>
              </li>
            ))}
          </ul>
        </MoreExpander>
      ))}
    </div>
  )
};


export default InfoLetters;
